import React from "react";

const ActiveRequestSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      style={{ enableBackground: "new 0 0 512 512" }}
      className="icon-lg me-3"
    >
      <g>
        <g>
          <path
            d="m480 200c4.418 0 8-3.582 8-8v-64c0-4.418-3.582-8-8-8h-208c-4.418 0-8 3.582-8 8v64c0 4.418 3.582 8 8 8h8v16h-8c-4.418 0-8 3.582-8 8v64c0 4.418 3.582 8 8 8h8v16h-8c-4.418 0-8 3.582-8 8v64c0 4.418 3.582 8 8 8h208c4.418 0 8-3.582 8-8v-64c0-4.418-3.582-8-8-8h-8v-16h8c4.418 0 8-3.582 8-8v-64c0-4.418-3.582-8-8-8h-8v-16zm-200-64h192v48h-192zm192 240h-192v-48h192zm-16-64h-160v-16h160zm16-32h-192v-48h192zm-16-64h-160v-16h160z"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></path>
          <path
            d="m304 168h64c4.418 0 8-3.582 8-8s-3.582-8-8-8h-64c-4.418 0-8 3.582-8 8s3.582 8 8 8z"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></path>
          <circle
            cx="448"
            cy="160"
            r="8"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></circle>
          <path
            d="m368 248h-64c-4.418 0-8 3.582-8 8s3.582 8 8 8h64c4.418 0 8-3.582 8-8s-3.582-8-8-8z"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></path>
          <circle
            cx="448"
            cy="256"
            r="8"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></circle>
          <path
            d="m368 344h-64c-4.418 0-8 3.582-8 8s3.582 8 8 8h64c4.418 0 8-3.582 8-8s-3.582-8-8-8z"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></path>
          <circle
            cx="448"
            cy="352"
            r="8"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></circle>
          <path
            d="m128 352c22.091 0 40-17.909 40-40-.017-28.518-16.865-54.338-42.96-65.84 21.108-16.038 25.217-46.151 9.179-67.259s-46.151-25.217-67.259-9.179-25.217 46.151-9.179 67.259c2.63 3.461 5.718 6.549 9.179 9.179-26.095 11.502-42.943 37.322-42.96 65.84 0 22.091 17.909 40 40 40zm-64-144c0-17.673 14.327-32 32-32s32 14.327 32 32-14.327 32-32 32-32-14.327-32-32zm-24 104c0-30.928 25.072-56 56-56s56 25.072 56 56c0 13.255-10.745 24-24 24h-64c-13.255 0-24-10.745-24-24z"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></path>
          <path
            d="m420.8 102.4 32-24c3.535-2.651 4.251-7.665 1.6-11.2s-7.665-4.251-11.2-1.6l-19.2 14.4v-16c0-22.091-17.909-40-40-40h-256c-22.091 0-40 17.909-40 40v48c0 4.418 3.582 8 8 8 4.418 0 8-3.582 8-8v-48c0-13.255 10.745-24 24-24h256c13.255 0 24 10.745 24 24v16l-19.2-14.4c-3.535-2.651-8.549-1.935-11.2 1.6s-1.935 8.549 1.6 11.2l32 24c.597.418 2.373 1.545 4.9 1.515 2.407-.029 4.094-1.091 4.7-1.515z"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></path>
          <path
            d="m91.28 393.6-32 24c-3.538 2.647-4.26 7.66-1.613 11.198s7.66 4.26 11.198 1.613c.005-.004.01-.007.015-.011l19.12-14.4v32c0 22.091 17.909 40 40 40h256c22.091 0 40-17.909 40-40v-32c0-4.418-3.582-8-8-8-4.418 0-8 3.582-8 8v32c0 13.255-10.745 24-24 24h-256c-13.255 0-24-10.745-24-24v-32l19.2 14.4c1.385 1.039 3.069 1.6 4.8 1.6 2.518 0 4.889-1.186 6.4-3.2 2.651-3.535 1.935-8.549-1.6-11.2l-32-24c-.469-.383-2.209-1.712-4.796-1.699-2.542.012-4.25 1.311-4.724 1.699z"
            fill="#f99f1e"
            data-original="#f99f1e"
            className=""
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default ActiveRequestSvg;

import React from 'react'

const RevenueSvg = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style={{ enableBackground: "new 0 0 512 512" }}
    xmlSpace="preserve"
    className="icon-lg"
  >
    <g>
      <title>Revenue</title>
      <path
        d="M404.469,283.627A6.1,6.1,0,0,0,410,277.655v-43.31a6.1,6.1,0,0,0-5.537-5.972l-27.957-2.7a121.1,121.1,0,0,0-14.144-33.433l17.727-21.4a6,6,0,0,0-.362-8.05l-30.246-30.554a6,6,0,0,0-8.095-.4L319.942,149.62a125.047,125.047,0,0,0-33.453-14.1l-2.7-27.961A6.12,6.12,0,0,0,277.82,102H234.447a6.119,6.119,0,0,0-5.972,5.556l-2.7,27.895a121.093,121.093,0,0,0-33.49,14.1l-21.4-17.731a6,6,0,0,0-8.068.38L132.2,162.762a6,6,0,0,0-.381,8.079l17.765,21.427a125.54,125.54,0,0,0-14.119,33.4l-27.981,2.7A6.056,6.056,0,0,0,102,234.345v43.31a6.054,6.054,0,0,0,5.484,5.972l27.93,2.7a121.1,121.1,0,0,0,14.131,33.433l-17.733,21.4a6,6,0,0,0,.384,8.076l30.618,30.555a6,6,0,0,0,8.069.371l21.443-17.779a125.047,125.047,0,0,0,33.453,14.1l2.7,27.961A6.119,6.119,0,0,0,234.447,410H277.82a6.118,6.118,0,0,0,5.972-5.556l2.7-27.9a121.088,121.088,0,0,0,33.49-14.1l21.405,17.731a6,6,0,0,0,8.068-.38l30.616-30.558a6,6,0,0,0,.38-8.079l-17.765-21.427a125.717,125.717,0,0,0,14.179-33.409Zm-38.354-4.162a113.763,113.763,0,0,1-15.848,37.48,6,6,0,0,0,.409,7.1L367.719,344.6,344.84,367.437l-20.564-17.051a6,6,0,0,0-7.139-.386,109.24,109.24,0,0,1-37.466,15.754,6.157,6.157,0,0,0-4.712,5.423L272.374,398h-32.48l-2.586-26.823a6.078,6.078,0,0,0-4.722-5.358,113.2,113.2,0,0,1-37.522-15.828,6,6,0,0,0-7.073.412l-20.563,17.042-22.879-22.837,17.043-20.557a6,6,0,0,0,.377-7.152,109.26,109.26,0,0,1-15.81-37.409,6.072,6.072,0,0,0-5.347-4.695L114,272.208V239.792l26.812-2.586a6.038,6.038,0,0,0,5.31-4.671,113.753,113.753,0,0,1,15.863-37.48,6,6,0,0,0-.4-7.1L144.545,167.4l22.881-22.833,20.564,17.051a6,6,0,0,0,7.14.386A109.231,109.231,0,0,1,232.6,146.246a6.156,6.156,0,0,0,4.712-5.423L239.894,114h32.48l2.585,26.823a6.081,6.081,0,0,0,4.722,5.358A113.2,113.2,0,0,1,317.2,162.009a6,6,0,0,0,7.072-.412l20.538-17.02,22.553,22.789L350.3,187.949a6,6,0,0,0-.377,7.152,109.26,109.26,0,0,1,15.81,37.409,6.134,6.134,0,0,0,5.4,4.7L398,239.792v32.422L371.5,274.8A6.135,6.135,0,0,0,366.115,279.465Z"
        fill="#32ccff"
        data-original="#32ccff"
      ></path>
      <path
        d="M255.978,161.419A94.581,94.581,0,1,0,350.69,256,94.754,94.754,0,0,0,255.978,161.419Zm0,177.162A82.581,82.581,0,1,1,338.69,256,82.741,82.741,0,0,1,255.978,338.581Z"
        fill="#32ccff"
        data-original="#32ccff"
      ></path>
      <path
        d="M256,227.3a11.32,11.32,0,0,1,11.307,11.307,6,6,0,0,0,12,0A23.347,23.347,0,0,0,263,216.376V212a6,6,0,0,0-12,0v3.846a23.306,23.306,0,0,0,5,46.069,11.307,11.307,0,1,1-11.307,11.307,6,6,0,0,0-12,0A23.346,23.346,0,0,0,250,295.742V302a6,6,0,0,0,12,0v-6.258a23.306,23.306,0,0,0-6-45.827,11.308,11.308,0,0,1,0-22.615Z"
        fill="#32ccff"
        data-original="#32ccff"
      ></path>
      <path
        d="M466.3,235.763a6,6,0,0,0-9.236,0l-38.2,46.055a6.3,6.3,0,0,0-.812,6.557A6.168,6.168,0,0,0,423.48,292h30.191a208.8,208.8,0,0,1-71.342,130.851c-36.759,31.519-82.945,49.508-126.715,49.508-28.551,0-58.4-7.737-86.314-22.293a214.454,214.454,0,0,1-73.489-63.058,6,6,0,1,0-9.681,7.075,226.467,226.467,0,0,0,77.622,66.583c29.622,15.445,61.387,23.607,91.862,23.607,46.592,0,95.625-18.956,134.526-52.312A222.137,222.137,0,0,0,465.764,292h34.112a6.206,6.206,0,0,0,4.619-10.094ZM436.252,280l25.426-30.656L487.105,280Z"
        fill="#32ccff"
        data-original="#32ccff"
      ></path>
      <path
        d="M93.95,223.625A6.168,6.168,0,0,0,88.52,220H58.329A208.8,208.8,0,0,1,129.671,89.149C166.43,57.63,212.616,39.641,256.386,39.641c28.551,0,58.4,7.737,86.314,22.293a214.454,214.454,0,0,1,73.489,63.058,6,6,0,1,0,9.681-7.075,226.467,226.467,0,0,0-77.622-66.583c-29.622-15.445-61.387-23.607-91.862-23.607-46.592,0-95.625,18.956-134.526,52.312A222.137,222.137,0,0,0,46.236,220H12.124a6.206,6.206,0,0,0-4.619,10.094l38.2,46.1a5.989,5.989,0,0,0,9.236.033l38.2-46.137A6.141,6.141,0,0,0,93.95,223.625ZM50.322,262.657,24.9,232H75.748Z"
        fill="#32ccff"
        data-original="#32ccff"
      ></path>
    </g>
  </svg>
  )
}

export default RevenueSvg

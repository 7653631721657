import React, { useEffect, useState, useMemo, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import UpdateStatusModal from "./UpdateStatusModal";
import ActiveApplications from "../../Applications/ActiveApplications/ActiveApplications";
import SupportTickets from "../../TicketingSystem/Tickets/SupportTickets";
import DashboardSvg from "../../../assets/svg/DashboardSvg";
import { decrypt } from "../../../utils/encryptDecrypt/encryptDecrypt";
import AnnouncementCarousel from "../AnnouncementCarousel";
import DepartmentUserInfo from "../../../common/UserInfo/DepartmentUserInfo";
import { hasViewPermission } from "../../../common/CommonFunctions/common";
import Loader, { LoaderSpin } from "../../../common/Loader/Loader";
import useAxios from "../../../utils/hook/useAxios";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css"; // Ensure you import necessary CSS files
import "react-resizable/css/styles.css"; // For resizable
import { ImCross } from "react-icons/im";
import { setTableColumnConfig } from "../../../slices/layouts/reducer";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import { Button } from "reactstrap";

const defaultCardsData = {
    announcements: true,
    tickets: true,
    applications: true,
    ticketsListing: true,
};

const defaultLayout = [
    {
        w: 12,
        h: 1.2,
        minW: 6,
        x: 0,
        y: 0,
        i: "announcements",
        moved: false,
        static: false,
    },

    {
        w: 12,
        h: 3,
        minH: 2,
        minW: 1,
        x: 8,
        y: 1.2,
        i: "tickets",
        moved: false,
        static: false,
    },
    {
        w: 12,
        h: 3,
        minH: 3,
        minW: 12,
        maxH: 10,
        x: 0,
        y: 12,
        i: "applications",
        moved: false,
        static: false,
        isResizable: false,
    },

    {
        w: 12,
        h: 3,
        minH: 3,
        minW: 12,
        maxH: 10,
        x: 0,
        y: 12,
        i: "ticketsListing",
        moved: false,
        static: false,
        isResizable: false,
    },
];

function calculatePercentages(statuses) {
    // Destructure the statuses object to get the counts
    const { completed, inProgress, new: newStatus, pending } = statuses;

    // Calculate the total number of items
    const total = completed + inProgress + newStatus + pending;

    // Calculate percentages
    const percentages = {
        completed: total === 0 ? 0 : (completed / total) * 100,
        inProgress: total === 0 ? 0 : (inProgress / total) * 100,
        new: total === 0 ? 0 : (newStatus / total) * 100,
        pending: total === 0 ? 0 : (pending / total) * 100,
    };

    return percentages;
}

const DepartmentAgentDashboard = () => {
    const axiosInstance = useAxios();
    const userEncryptData = localStorage.getItem("userData");
    const userDecryptData = useMemo(() => {
        return userEncryptData ? decrypt({ data: userEncryptData }) : {};
    }, [userEncryptData]);
    const userData = userDecryptData?.data;
    const userId = userData?.id;

    const departmentId = userData?.departmentId;
    const dispatch = useDispatch();
    const tableName = "departmentAgentDashboard";
    const tableConfigList = useSelector(
        (state) => state?.Layout?.tableColumnConfig
    );
    const tableColumnConfig = tableConfigList?.find(
        (config) => config?.tableName === tableName
    );

    const tableConfigLoading = useSelector(
        (state) => state?.Layout?.configDataLoading
    );

    const [ticketCount, setTicketCount] = useState();
    const [ticketCountPercentage, setTicketCountPercentage] = useState();
    const [data, setData] = useState("");
    const visibleRecords = 5;
    const navigate = useNavigate();
    const [dataById, setDataById] = useState([]);
    const [loading, setLoading] = useState(true);
    const [announcementloading, setAnnouncementLoading] = useState(true);
    const [updateLayout, setUpdateLayout] = useState(false);

    const [isOpen, setIsopen] = useState(false);

    const userPermissionsEncryptData = localStorage.getItem("userPermissions");
    const userPermissionsDecryptData = userPermissionsEncryptData
        ? decrypt({ data: userPermissionsEncryptData })
        : { data: [] };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [layoutReady, setLayoutReady] = useState(false);
    const [cardsData, setCardsData] = useState({});
    const [layout, setLayout] = useState([]);

    const { memoizedLayoutData, memoizedCardsData } = useMemo(() => {
        const hasValidConfig =
            tableColumnConfig?.tableConfig &&
            Object.keys(tableColumnConfig.tableConfig).length > 0;

        if (hasValidConfig) {
            return {
                memoizedLayoutData: tableColumnConfig.tableConfig.layoutData,
                memoizedCardsData: tableColumnConfig.tableConfig.cardsData,
            };
        }

        // Return defaults if no valid Redux data
        return {
            memoizedLayoutData: defaultLayout,
            memoizedCardsData: defaultCardsData,
        };
    }, [tableColumnConfig?.tableConfig, defaultLayout, defaultCardsData]);

    // Update states when memoized data changes
    useEffect(() => {
        if (memoizedLayoutData) setLayout(memoizedLayoutData);
        if (memoizedCardsData) setCardsData(memoizedCardsData);
    }, [memoizedLayoutData, memoizedCardsData]);

    // Memoize layout processing
    const processedLayout = useMemo(() => {
        if (!Object.keys(cardsData || {}).length) {
            return defaultLayout;
        }

        return defaultLayout
            .filter((item) => cardsData[item.i])
            .map((item) => {
                const existingItem = layout?.find(
                    (layoutItem) => layoutItem.i === item.i
                );
                return existingItem || item;
            });
    }, [cardsData, layout, defaultLayout]);

    const isLayoutReady = !!processedLayout;
    useEffect(() => {
        if (isLayoutReady && !tableConfigLoading) {
            setLayoutReady(true);
        }
    }, [isLayoutReady, tableConfigLoading]);

    // Memoize width calculation
    const gridWidth = useMemo(
        () => window.innerWidth - (updateLayout ? (280) : 80),
        [updateLayout, isOpen]
    );

    // Optimize layout change handler
    const handleLayoutChange = useCallback(
        (newLayout) => {
            const hasLayoutChanged = layout?.some((item, index) => {
                const newItem = newLayout[index];
                return (
                    item?.x !== newItem?.x ||
                    item?.y !== newItem?.y ||
                    item?.w !== newItem?.w ||
                    item?.h !== newItem?.h
                );
            });

            if (hasLayoutChanged) {
                setLayout(newLayout);
            }
        },
        [layout]
    );

    const toggleUpdateLayout = () => {
        if (updateLayout) {
            setUpdateLayout(false);
            updateTableConfig();
        }
    };

    const hanldeResetLayout = () => {
        setUpdateLayout(false);
        resetLayoutTable();
    };

    const setTrueIfFalse = (key) => {
        setCardsData((prevData) => ({
            ...prevData,
            [key]: !prevData[key],
        }));
    };

    const setFalse = (key) => {
        setCardsData((prevData) => ({
            ...prevData,
            [key]: false,
        }));
    };

    const ToggleSidebar = () => {
        setIsopen(!isOpen);
    };

    const fetchTableConfigData = async () => {
        try {
            if (userId) {
                const response = await axiosInstance.post(
                    `userService/table/get-table-config`,
                    {
                        userId: userId,
                    }
                );

                if (response) {
                    const data = response?.data?.data;
                    dispatch(setTableColumnConfig(data));
                }
            }
        } catch (error) {
            console.error("Error fetching profile image:", error.message);
        }
    };

    const updateTableConfig = async () => {
        try {
            const response = await axiosInstance.post(
                `userService/table/update-table-config`,
                {
                    userId: userId,
                    tableName: tableName,
                    tableConfig: JSON.stringify({
                        cardsData: cardsData,
                        layoutData: layout,
                    }),
                }
            );
            if (response) {
                fetchTableConfigData();
            }
        } catch (error) {
            console.error("Something went wrong while update");
        }
    };

    const resetLayoutTable = async () => {
        try {
            const response = await axiosInstance.post(
                `userService/table/update-table-config`,
                {
                    userId: userId,
                    tableName: tableName,
                    tableConfig: JSON.stringify({
                        cardsData: defaultCardsData,
                        layoutData: defaultLayout,
                    }),
                }
            );
            if (response) {
                fetchTableConfigData();
            }
        } catch (error) {
            console.error("Something went wrong while update");
        }
    };

    const slugsToCheck = ["announcements", "applications", "tickets"];

    const getPermission = (slug) => {
        const permission = userPermissionsDecryptData?.data?.find(
            (module) => module.slug === slug
        );
        return permission ? hasViewPermission(permission) : false;
    };

    const permissions = {};

    slugsToCheck.forEach((slug) => {
        permissions[slug] = getPermission(slug);
    });

    const announcementsViewPermission = permissions["announcements"];
    const applicationsViewPermission = permissions["applications"];
    const ticketsViewPermission = permissions["tickets"];

    const fetchDepartmentById = async () => {
        // setIsLoading(true)
        try {
            const response = await axiosInstance.post(
                `serviceManagement/department/departmentById`,
                { departmentId: departmentId }
            );
            if (response?.data) {
                const { rows, count } = response?.data?.data;
                setDataById(rows);
                // setIsLoading(false)
            }
        } catch (error) {
            // setIsLoading(false)
            console.error(error.message);
        }
    };
    const fetchTicketCount = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.post(
                `ticketService/ticket/statusCount`,
                { departmentId: departmentId }
            );
            if (response?.data) {
                const data = response?.data?.data;
                setTicketCount(data);
                // setIsLoading(false)
                if (data) {
                    let percentage = calculatePercentages(data);
                    setTicketCountPercentage(percentage);
                    setLoading(false);
                }
            }
        } catch (error) {
            setLoading(false);
            console.error(error.message);
        }
    };

    useEffect(() => {
        if (userData?.isCoreTeam === "0") {
            fetchDepartmentById();
        }
    }, []);
    useEffect(() => {
        if (userData?.departmentId) {
            fetchTicketCount();
        }
    }, []);

    function toggleCardVisibility(cardId) {
        const card = document.getElementById(cardId);
        if (card) {
            card.classList.toggle("d-none");
        }
    }

    const getCurrentFormattedDate = () => {
        const options = { month: "short", day: "numeric", year: "numeric" };
        const today = new Date();
        const dateString = today.toLocaleDateString("en-US", options);
        return dateString.replace(",", "");
    };

    const listOfAnnouncements = async () => {
        try {
            setAnnouncementLoading(true);
            const response = await axiosInstance.post(
                `userService/announcement/view`,
                {}
            );
            if (response?.data) {
                const { rows } = response?.data?.data;
                setData(rows);
                setAnnouncementLoading(false);
            }
        } catch (error) {
            setAnnouncementLoading(false);
            console.error("No results found.");
        }
    };

    useEffect(() => {
        listOfAnnouncements();
    }, []);

    document.title = "Dashboard | eGov Solution";

    const menuItems = [
        {
            title: "Announcement",
            icon: "ri-bar-chart-2-line",
            cardValue: "announcements",
            onClick: () => setTrueIfFalse("announcements"),
        },
        {
            title: "Tickets",
            icon: "ri-coupon-line",
            style: { transform: "rotate(90deg)" },
            cardValue: "tickets",
            onClick: () => setTrueIfFalse("tickets"),
        },
        {
            title: "Recent Applications",
            icon: "mdi mdi-sitemap-outline",
            cardValue: "applications",
            onClick: () => setTrueIfFalse("applications"),
        },
        {
            title: "Recent Tickets",
            icon: "ri-customer-service-line",
            cardValue: "ticketsListing",
            onClick: () => setTrueIfFalse("ticketsListing"),
        },
    ].map((menu) => ({
        ...menu,
        cardAdded: cardsData[menu.cardValue] || false,
    }));

    return (
        <>
            <div className="main-content dashboard-ana">
                <div
                    className={`page-content custom-sidebar ${updateLayout ? "menu--open" : ""
                        } px-0  `}>
                    {
                        !updateLayout && !isMobile && (
                            <Button onClick={() => setUpdateLayout(true)} className="edit-layout bg-primary" size="sm" varian="secondary" title="Edit Layout">
                                <i className="mdi mdi-cog-outline fs-16 align-middle me-1 rotating-icon"></i>
                            </Button>
                        )
                    }
                    {isMobile ? (
                        <div className="container-fluid">
                            <div className="row">
                                <DepartmentUserInfo />
                                <div className="col-lg-9">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h5 className="flex-grow-1 mb-0">
                                                        Announcements
                                                    </h5>
                                                </div>

                                                {announcementloading ? (
                                                    <>
                                                        <div
                                                            className="card-body"
                                                            style={{
                                                                height: "",
                                                                padding:
                                                                    "35px 20px",
                                                            }}>
                                                            <LoaderSpin />
                                                        </div>
                                                    </>
                                                ) : !announcementloading &&
                                                    data.length === 0 ? (
                                                    <>
                                                        <div className="text-center">
                                                            <p className="text-muted">
                                                                No Announcement
                                                                found.
                                                            </p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="card-body"
                                                            style={{
                                                                height: "",
                                                                padding:
                                                                    "35px 20px",
                                                            }}>
                                                            <AnnouncementCarousel
                                                                items={data}
                                                                announcementsViewPermission={
                                                                    announcementsViewPermission
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card border-0">
                                                <div className="card-header">
                                                    <h4 className="card-title mb-0 flex-grow-1 fs-18 fw-semibold">
                                                        Tickets
                                                    </h4>
                                                </div>

                                                {loading ? (
                                                    <div className="card-body border-0 pb-2 pt-2 draggableHandle">
                                                        <LoaderSpin
                                                            height={"300px"}
                                                        />
                                                    </div>
                                                ) : !loading &&
                                                    ticketsViewPermission?.length ===
                                                    0 ? (
                                                    <div className="text-center">
                                                        <p className="text-muted">
                                                            No Ticket Data
                                                            found.
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="card-body border-0 pb-2 pt-2">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-1 text-muted">
                                                                        New
                                                                    </h6>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <h6 className="mb-0">
                                                                        {ticketsViewPermission
                                                                            ? ticketCount?.new ||
                                                                            null
                                                                            : null}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="progress animated-progress progress-sm bg-soft-secondary"
                                                                style={{
                                                                    borderRadius:
                                                                        "10px",
                                                                }}>
                                                                <div
                                                                    className="progress-bar"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#405189",
                                                                        width: `${ticketsViewPermission
                                                                            ? ticketCountPercentage?.new ||
                                                                            null
                                                                            : null
                                                                            }%`,
                                                                        borderRadius:
                                                                            "10px",
                                                                    }}
                                                                    role="progressbar"
                                                                    aria-valuenow="49"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body border-0 pb-2 pt-2">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-1 text-muted">
                                                                        In
                                                                        Progress
                                                                    </h6>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <h6 className="mb-0">
                                                                        {ticketsViewPermission
                                                                            ? ticketCount?.inProgress ||
                                                                            null
                                                                            : null}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="progress animated-progress progress-sm bg-soft-secondary"
                                                                style={{
                                                                    borderRadius:
                                                                        "10px",
                                                                }}>
                                                                <div
                                                                    className="progress-bar"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#b15444",
                                                                        width: `${ticketsViewPermission
                                                                            ? ticketCountPercentage?.inProgress ||
                                                                            null
                                                                            : null
                                                                            }%`,
                                                                        borderRadius:
                                                                            "10px",
                                                                    }}
                                                                    role="progressbar"
                                                                    aria-valuenow="90"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body border-0 pb-2 pt-2">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-1 text-muted">
                                                                        Pending
                                                                    </h6>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <h6 className="mb-0">
                                                                        {ticketsViewPermission
                                                                            ? ticketCount?.pending ||
                                                                            null
                                                                            : null}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="progress animated-progress progress-sm bg-soft-secondary"
                                                                style={{
                                                                    borderRadius:
                                                                        "10px",
                                                                }}>
                                                                <div
                                                                    className="progress-bar"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#f7b84b",
                                                                        width: `${ticketsViewPermission
                                                                            ? ticketCountPercentage?.pending ||
                                                                            null
                                                                            : null
                                                                            }%`,
                                                                        borderRadius:
                                                                            "10px",
                                                                    }}
                                                                    role="progressbar"
                                                                    aria-valuenow="90"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>

                                                        <div className="card-body border-0">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-1 text-muted">
                                                                        Completed
                                                                    </h6>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <h6 className="mb-0">
                                                                        {ticketsViewPermission
                                                                            ? ticketCount?.completed ||
                                                                            null
                                                                            : null}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="progress animated-progress progress-sm bg-soft-secondary"
                                                                style={{
                                                                    borderRadius:
                                                                        "10px",
                                                                }}>
                                                                <div
                                                                    className="progress-bar"
                                                                    role="progressbar"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#0ab39c",
                                                                        width: `${ticketsViewPermission
                                                                            ? ticketCountPercentage?.completed ||
                                                                            null
                                                                            : null
                                                                            }%`,
                                                                        borderRadius:
                                                                            "10px",
                                                                    }}
                                                                    aria-valuenow="10"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {applicationsViewPermission && (
                                <div className="row ">
                                    <div className="col-lg-12">
                                        <div className="card border-0">
                                            <div className="card-header border-bottom">
                                                <h5 className="mb-0">
                                                    Recent Applications
                                                </h5>
                                            </div>
                                            <ActiveApplications
                                                isDashBoard={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {ticketsViewPermission && (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card mb-0 border-0">
                                            <div className="card-header border-bottom">
                                                <h5 className="mb-0">
                                                    Recent Tickets
                                                </h5>
                                            </div>
                                            <SupportTickets
                                                isDashBoard={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="container-fluid">
                            {/* <div className="row"> */}
                            <DepartmentUserInfo />
                            <div className="d-flex align-items-center justify-content-end">
                                <Button onClick={() => hanldeResetLayout()}
                                    size="sm" color="secondary" className="me-3">
                                    Reset Layout
                                </Button>
                            </div>
                            {/* <div>
                                <button
                                    onClick={toggleUpdateLayout}
                                    className="btn btn-primary d-flex align-items-center justify-content-center ms-auto"
                                    type="button">
                                    {updateLayout
                                        ? "Save Layout"
                                        : "Edit Layout"}
                                </button>
                            </div> */}
                            {
                                tableConfigLoading && (
                                    <div>
                                        <LoaderSpin />
                                    </div>
                                )
                            }
                            {
                                layoutReady && processedLayout?.length === 0 && (
                                    <div className="text-center dashboard-blank">
                                        <DashboardSvg />
                                        <h5 className="mt-4">
                                            Nothing to show please update the layout
                                        </h5>
                                    </div>
                                )
                            }
                            {
                                layoutReady && processedLayout?.length > 0 && (
                                    <GridLayout
                                        onLayoutChange={handleLayoutChange}
                                        className="layout"
                                        layout={processedLayout}
                                        cols={12}
                                        width={gridWidth}
                                        margin={[10, 10]}
                                        useCSSTransforms={true}
                                        draggableHandle={
                                            updateLayout ? ".draggableHandle" : ""
                                        }
                                        isResizable={updateLayout}
                                        isDraggable={updateLayout}>
                                        {cardsData?.announcements && (
                                            <div key="announcements" className={
                                                updateLayout
                                                    ? "card cursor-grab "
                                                    : "card "
                                            }>
                                                {updateLayout && (
                                                    <button
                                                        title="Remove This block"
                                                        type="button"
                                                        className="grid-close-btn"
                                                        onClick={() =>
                                                            setFalse(
                                                                "announcements"
                                                            )
                                                        }>
                                                        <ImCross size="10px" />
                                                    </button>
                                                )}
                                                <div className="card-header d-flex align-items-center draggableHandle">
                                                    <h5 className={`flex-grow-1 mb-0 ${updateLayout ? "user-select-none" : ""}`}>
                                                        Announcements
                                                    </h5>
                                                </div>
                                                {announcementloading ? (
                                                    <>
                                                        <div className="card-body p-3">
                                                            <LoaderSpin height="100px" />
                                                        </div>
                                                    </>
                                                ) : !announcementloading &&
                                                    data.length === 0 ? (
                                                    <>
                                                        <div className="text-center">
                                                            <p className="text-muted">
                                                                No Announcement found.
                                                            </p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="card-body p-3 draggableHandle">
                                                        <AnnouncementCarousel
                                                            items={data}
                                                            announcementsViewPermission={
                                                                announcementsViewPermission
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {cardsData?.tickets && (
                                            <div
                                                key="tickets"
                                                className={
                                                    updateLayout
                                                        ? "card cursor-grab "
                                                        : "card "
                                                }>
                                                {updateLayout && (
                                                    <div className="mx-4 flex-shrink-0">
                                                        <button
                                                            type="button"
                                                            className="grid-close-btn"
                                                            onClick={() =>
                                                                setFalse(
                                                                    "tickets",
                                                                    cardsData
                                                                )
                                                            }>
                                                            <ImCross size="10px" />
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="card-header align-items-center d-flex flex-wrap justify-content-between draggableHandle">
                                                    <h4 className={`card-title mb-0 flex-grow-1 fs-18 fw-semibold ${updateLayout ? "user-select-none" : ""}`}>
                                                        Tickets
                                                    </h4>
                                                </div>

                                                {loading ? (
                                                    <div className="card-body border-0 pb-2 pt-2 draggableHandle">
                                                        <LoaderSpin height={"300px"} />
                                                    </div>
                                                ) : !loading &&
                                                    ticketsViewPermission?.length ===
                                                    0 ? (
                                                    <div className="text-center">
                                                        <p className="text-muted">
                                                            No Ticket Data found.
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="card-body border-0 pb-2 pt-2 draggableHandle">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-1 text-muted">
                                                                        New
                                                                    </h6>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <h6 className="mb-0">
                                                                        {ticketsViewPermission
                                                                            ? ticketCount?.new ||
                                                                            null
                                                                            : null}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class Name="progress animated-progress progress-sm bg-soft-secondary"
                                                                style={{
                                                                    borderRadius:
                                                                        "10px",
                                                                }}>
                                                                <div
                                                                    className="progress-bar"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#405189",
                                                                        width: `${ticketsViewPermission
                                                                            ? ticketCountPercentage?.new ||
                                                                            null
                                                                            : null
                                                                            }%`,
                                                                        borderRadius:
                                                                            "10px",
                                                                    }}
                                                                    role="progressbar"
                                                                    aria-valuenow="49"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body border-0 pb-2 pt-2">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-1 text-muted">
                                                                        In Progress
                                                                    </h6>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <h6 className="mb-0">
                                                                        {ticketsViewPermission
                                                                            ? ticketCount?.inProgress ||
                                                                            null
                                                                            : null}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="progress animated-progress progress-sm bg-soft-secondary"
                                                                style={{
                                                                    borderRadius:
                                                                        "10px",
                                                                }}>
                                                                <div
                                                                    className="progress-bar"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#b15444",
                                                                        width: `${ticketsViewPermission
                                                                            ? ticketCountPercentage?.inProgress ||
                                                                            null
                                                                            : null
                                                                            }%`,
                                                                        borderRadius:
                                                                            "10px",
                                                                    }}
                                                                    role="progressbar"
                                                                    aria-valuenow="90"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body border-0 pb-2 pt-2">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-1 text-muted">
                                                                        Pending
                                                                    </h6>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <h6 className="mb-0">
                                                                        {ticketsViewPermission
                                                                            ? ticketCount?.pending ||
                                                                            null
                                                                            : null}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="progress animated-progress progress-sm bg-soft-secondary"
                                                                style={{
                                                                    borderRadius:
                                                                        "10px",
                                                                }}>
                                                                <div
                                                                    className="progress-bar"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#f7b84b",
                                                                        width: `${ticketsViewPermission
                                                                            ? ticketCountPercentage?.pending ||
                                                                            null
                                                                            : null
                                                                            }%`,
                                                                        borderRadius:
                                                                            "10px",
                                                                    }}
                                                                    role="progressbar"
                                                                    aria-valuenow="90"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>

                                                        <div className="card-body border-0">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-1 text-muted">
                                                                        Completed
                                                                    </h6>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <h6 className="mb-0">
                                                                        {ticketsViewPermission
                                                                            ? ticketCount?.completed ||
                                                                            null
                                                                            : null}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="progress animated-progress progress-sm bg-soft-secondary"
                                                                style={{
                                                                    borderRadius:
                                                                        "10px",
                                                                }}>
                                                                <div
                                                                    className="progress-bar"
                                                                    role="progressbar"
                                                                    style={{
                                                                        backgroundColor:
                                                                            "#0ab39c",
                                                                        width: `${ticketsViewPermission
                                                                            ? ticketCountPercentage?.completed ||
                                                                            null
                                                                            : null
                                                                            }%`,
                                                                        borderRadius:
                                                                            "10px",
                                                                    }}
                                                                    aria-valuenow="10"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        {cardsData?.applications && (
                                            <div
                                                key="applications"
                                                className={`card ${updateLayout ? "cursor-grab " : ""
                                                    }`}>
                                                {updateLayout && (
                                                    <button
                                                        type="button"
                                                        className="grid-close-btn"
                                                        onClick={() =>
                                                            setFalse("applications")
                                                        }>
                                                        <ImCross size="10px" />
                                                    </button>
                                                )}
                                                <div className="card-header border-bottom align-items-center d-flex flex-wrap justify-content-between draggableHandle">
                                                    <h5 className="mb-0">
                                                        Recent Applications
                                                    </h5>

                                                </div>
                                                <ActiveApplications className="draggableHandle"
                                                    isDashBoard={true}
                                                />
                                            </div>
                                        )}
                                        {cardsData?.ticketsListing && (
                                            <div
                                                key="ticketsListing"
                                                className={`${ticketsViewPermission
                                                    ? "card border-0"
                                                    : "d-none"
                                                    } ${updateLayout ? "cursor-grab " : ""
                                                    }`}>
                                                {updateLayout && (
                                                    <div className="mx-4 flex-shrink-0">
                                                        <button
                                                            type="button"
                                                            className="grid-close-btn"
                                                            onClick={() =>
                                                                setFalse(
                                                                    "ticketsListing",
                                                                    cardsData
                                                                )
                                                            }>
                                                            <ImCross size="10px" />
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="card-header border-bottom align-items-center d-flex flex-wrap justify-content-between draggableHandle">
                                                    <h5 className="mb-0 ">
                                                        Recent Tickets
                                                    </h5>
                                                </div>
                                                <SupportTickets isDashBoard={true} className="draggableHandle" />
                                            </div>
                                        )}
                                    </GridLayout>
                                )
                            }
                        </div>
                    )}

                    <div class="custom-sidebar-menu bg-primary">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <Button onClick={() => hanldeResetLayout()} size="sm" color="secondary">
                                Reset
                            </Button>
                            <Button onClick={toggleUpdateLayout} size="sm" color="success">
                                Save Changes
                            </Button>
                            <button
                                type="button"
                                className="sidebar-dash-close-btn btn btn-sm btn-danger"
                                onClick={() =>
                                    setUpdateLayout(false)
                                }>
                                <ImCross size="10px" />
                            </button>
                        </div>

                        <div className="mt-4" >
                            <SimpleBar style={{ maxHeight: 'calc(90vh - 80px)', overflowX: 'auto' }}>
                                {menuItems.map((item, index) => (
                                    <div key={index} className={item?.cardAdded ? "menu-item active" : "menu-item"} title={item.title} onClick={item.onClick}>
                                        <div className="menu-toggle-icon">
                                            <i className={`${item.icon} fs-20 text-white`} style={item.style || {}}></i>
                                        </div>
                                        <div className="menu-item-name cursor-pointer">{item.title}
                                            <div className="menu-item-check-box">
                                                <i class="ri-checkbox-blank-circle-line"></i>
                                                <i class="ri-radio-button-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </SimpleBar>
                        </div>
                        {/* <div
                            onClick={ToggleSidebar}
                            className="menu-toggle sidebar ">
                            <i class="ri-menu-2-line fs-20 text-white"></i>
                            <i class="ri-close-line fs-20 text-white"></i>
                        </div> */}
                    </div>

                </div>

                <UpdateStatusModal />
            </div>
        </>
    );
};

export default DepartmentAgentDashboard;

import React from "react";
import { Container, Row, Col, Table } from "reactstrap";

const CredentialDetails = () => {
    const users = [
        {
            department: "Core User",
            username: "Luis Henderson",
            email: "smewada@netclues.in",
            password: "S@123456",
        },
        {
            department: "Registrar General's Department",
            username: "Kenneth Martin (Department Admin)",
            email: "ks@netclues.in",
            password: "S@123456",
        },
        {
            department: "Registrar General's Department",
            username: "Brandon Cooper (Department Agent)",
            email: "sanand@gamil.com",
            password: "S@123456",
        },
        {
            department: "Royal bahamas Police",
            username: "Vivien Bridges (Department Admin)",
            email: "skasundra@netclues.in",
            password: "S@123456",
        },
        {
            department: "Royal bahamas Police",
            username: "Charles Rodriguez (Department Agent)",
            email: "anjali@gmail.com",
            password: "S@123456",
        },
        {
            department: "Department of Inland Revenue",
            username: "Brandon Cooper (Department Agent)",
            email: "sanand@gamil.com",
            password: "S@123456",
        },
    ];

    const CustomerUsers = [
        {
            username: "Donald Trump",
            email: "pthakur.netclues@gmail.com",
            password: "S@123456",
        },
        {
            username: "Jordan Williamson",
            email: "satish@gmail.com",
            password: "S@123456",
        },
    ];

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert(`Copied: ${text}`);
    };

    return (
        <Container className="page-content">
            <Row className="align-items-center">
                <Col xs="12">
                    <div className="text-center mb-4">
                        <a
                            href="https://govconnect.netcluesdemo.com/egov/"
                            title="Netclues">
                            <img
                                src="https://govconnect.netcluesdemo.com/egov/front/assets/images/logo-dark-d.png"
                                alt="Netclues"
                                width="150"
                            />
                        </a>
                    </div>
                    <h3 className="text-center">Admin Side</h3>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Department</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Password</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{user.department}</td>
                                    <td>{user.username}</td>
                                    <td>
                                        <span className="me-2">
                                        {user.email}
                                        </span>
                                        <span
                                            class="cursor-pointer me-2"
                                            onClick={() =>
                                                copyToClipboard(user.email)
                                            }>
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 448 512"
                                                color="#f99f1e"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path>
                                            </svg>
                                        </span>
                                    </td>
                                    <td>
                                    <span className="me-2">
                                        {user.password}
                                        </span>
                                        <span
                                            class="cursor-pointer me-2"
                                            onClick={() =>
                                                copyToClipboard(user.password)
                                            }>
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 448 512"
                                                color="#f99f1e"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path>
                                            </svg>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <h3 className="text-center">Customer</h3>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Password</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CustomerUsers.map((user, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{user.username}</td>
                                    <td>
                                    <span className="me-2">
                                        {user.email}
                                        </span>
                                        <span
                                            class="cursor-pointer me-2"
                                            onClick={() =>
                                                copyToClipboard(user.email)
                                            }>
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 448 512"
                                                color="#f99f1e"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path>
                                            </svg>
                                        </span>
                                    </td>
                                    <td>
                                    <span className="me-2">
                                        {user.password}
                                        </span>
                                        <span
                                            class="cursor-pointer me-2"
                                            onClick={() =>
                                                copyToClipboard(user.password)
                                            }>
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 448 512"
                                                color="#f99f1e"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path>
                                            </svg>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default CredentialDetails;

import React from 'react'

const ActiveUsersSvg = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style={{ enableBackground: "new 0 0 512 512" }}
    xmlSpace="preserve"
    className="me-3 icon-lg"
  >
    <g>
      <path
        d="M21,512a4.2,4.2,0,0,1-4.2-4.2V5.4a4.2,4.2,0,1,1,8.39,0V507.8A4.2,4.2,0,0,1,21,512Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M4.19,32.62A4.2,4.2,0,0,1,.75,26L17.57,1.8A4.19,4.19,0,0,1,21,0h0a4.19,4.19,0,0,1,3.45,1.8L41.27,26a4.2,4.2,0,0,1-6.9,4.79L21,11.56,7.64,30.82A4.19,4.19,0,0,1,4.19,32.62Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M503.24,495.18H4.2a4.2,4.2,0,1,1,0-8.39h499a4.2,4.2,0,0,1,0,8.39Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M483.58,512a4.2,4.2,0,0,1-2.4-7.65L500.44,491l-19.26-13.37a4.2,4.2,0,1,1,4.79-6.9l24.23,16.82a4.2,4.2,0,0,1,0,6.9L486,511.25A4.18,4.18,0,0,1,483.58,512Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M458.64,34.32a4.2,4.2,0,0,1-3.72-6.14L464.42,10l-22.31.69a4.2,4.2,0,0,1-.26-8.39l29.48-.91a4.11,4.11,0,0,1,3.68,2,4.19,4.19,0,0,1,.17,4.17L462.36,32.07A4.19,4.19,0,0,1,458.64,34.32Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M102.6,481.15a16.55,16.55,0,0,1-16.48-15.46l-1-14.23-1,14.27a16.51,16.51,0,0,1-33-2.17l5-67.13A14.84,14.84,0,0,1,38,402.05l-.12,0a14.85,14.85,0,0,1-8.6-17.56l0-.11,12.1-40.09a40.13,40.13,0,0,1,38.65-29.52H90.13a40.13,40.13,0,0,1,38.64,29.52l12.1,40.09,0,.11A14.83,14.83,0,0,1,114,396.24l5,67.28a16.51,16.51,0,0,1-16.47,17.63Zm-17.51-79.5a5.13,5.13,0,0,1,5.16,4.8l4.24,58.67a8.14,8.14,0,0,0,8.1,7.64,8.12,8.12,0,0,0,8.1-8.65l-5.27-70.56-2-38.91a4.2,4.2,0,0,1,8.27-1.19l8.64,36.36a6.44,6.44,0,1,0,12.49-3.12l-12.1-40.08,0-.11a31.72,31.72,0,0,0-30.56-23.38H80.06A31.72,31.72,0,0,0,49.5,346.49l0,.11-12.1,40.08a6.45,6.45,0,0,0,3.76,7.58l.12,0a6.44,6.44,0,0,0,8.68-4.43L59,353a4.2,4.2,0,0,1,8.26,1.26L64.75,393.5l-5.27,70.65a8.12,8.12,0,0,0,8.1,8.61,8.13,8.13,0,0,0,8.1-7.6l4.24-58.7a5.13,5.13,0,0,1,5.16-4.8Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M85.09,321.32a26.06,26.06,0,1,1,26.06-26.06A26.08,26.08,0,0,1,85.09,321.32Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,85.09,277.6Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M220.52,481.15A16.54,16.54,0,0,1,204,465.69l-1-14.23-1,14.27a16.51,16.51,0,0,1-33-2.17l5-67.13a14.83,14.83,0,0,1-18.12,5.63l-.1,0a14.86,14.86,0,0,1-8.62-17.57l0-.11,12.1-40.09A40.13,40.13,0,0,1,198,314.72h10.07a40.13,40.13,0,0,1,38.65,29.52l12.1,40.09,0,.11A14.83,14.83,0,0,1,232,396.24l5,67.28a16.51,16.51,0,0,1-16.47,17.63ZM203,401.65h0a5.13,5.13,0,0,1,5.16,4.81l4.24,58.66a8.14,8.14,0,0,0,8.1,7.64,8.12,8.12,0,0,0,8.1-8.65l-5.27-70.56-2-38.91a4.2,4.2,0,0,1,8.27-1.19l8.64,36.36a6.44,6.44,0,1,0,12.49-3.12l-12.1-40.08,0-.11a31.72,31.72,0,0,0-30.56-23.38H198a31.72,31.72,0,0,0-30.56,23.38l0,.11-12.1,40.08a6.45,6.45,0,0,0,3.76,7.58l.1,0a6.44,6.44,0,0,0,8.7-4.42l9-36.91a4.2,4.2,0,0,1,8.26,1.26l-2.49,39.26-5.27,70.65a8.12,8.12,0,0,0,8.1,8.61,8.13,8.13,0,0,0,8.1-7.6l4.24-58.7A5.13,5.13,0,0,1,203,401.65Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M285.79,286.43a4.17,4.17,0,0,1-2.2-.63A31.63,31.63,0,0,0,267,281.1H256.9a31.63,31.63,0,0,0-16.61,4.71,4.2,4.2,0,1,1-4.41-7.14,40,40,0,0,1,21-6H267a40,40,0,0,1,21,6,4.2,4.2,0,0,1-2.21,7.77Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M403.63,286.43a4.17,4.17,0,0,1-2.2-.63,31.61,31.61,0,0,0-16.61-4.71H374.75a31.63,31.63,0,0,0-16.61,4.71,4.2,4.2,0,1,1-4.41-7.14,40,40,0,0,1,21-6h10.07a40,40,0,0,1,21,6,4.2,4.2,0,0,1-2.21,7.77Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M167.94,286.43a4.17,4.17,0,0,1-2.2-.63,31.63,31.63,0,0,0-16.61-4.71H139.06a31.63,31.63,0,0,0-16.61,4.71,4.2,4.2,0,1,1-4.41-7.14,40,40,0,0,1,21-6h10.07a40,40,0,0,1,21,6,4.2,4.2,0,0,1-2.21,7.77Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M203,323a26.06,26.06,0,1,1,25.66-30.87h0a26.06,26.06,0,0,1-20.8,30.42A26.39,26.39,0,0,1,203,323Zm.09-43.73a17.69,17.69,0,0,0-17.4,20.93,17.66,17.66,0,1,0,34.72-6.52h0a17.67,17.67,0,0,0-17.32-14.41Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M338.45,481.15A16.54,16.54,0,0,1,322,465.69l-1-14.23-1,14.27a16.51,16.51,0,0,1-33-2.17l5-67.13a14.83,14.83,0,0,1-18.12,5.63l-.12,0a14.85,14.85,0,0,1-8.6-17.56l0-.11,12.1-40.09a40.13,40.13,0,0,1,38.65-29.52H326a40.13,40.13,0,0,1,38.65,29.52l12.1,40.09,0,.11a14.83,14.83,0,0,1-26.85,11.8l5,67.28a16.51,16.51,0,0,1-16.47,17.63Zm-17.51-79.5a5.13,5.13,0,0,1,5.16,4.8l4.24,58.67a8.14,8.14,0,0,0,8.1,7.64,8.12,8.12,0,0,0,8.1-8.65l-5.27-70.56-2-38.91a4.2,4.2,0,0,1,8.27-1.19l8.64,36.36a6.44,6.44,0,1,0,12.49-3.12l-12.1-40.08,0-.11A31.73,31.73,0,0,0,326,323.11H315.91a31.72,31.72,0,0,0-30.56,23.38l0,.11-12.1,40.08a6.45,6.45,0,0,0,3.76,7.58l.12,0a6.44,6.44,0,0,0,8.68-4.43l9-36.91a4.2,4.2,0,0,1,8.26,1.26l-2.48,39.26-5.27,70.65a8.12,8.12,0,0,0,8.1,8.61,8.13,8.13,0,0,0,8.1-7.6l4.24-58.7A5.13,5.13,0,0,1,320.94,401.65Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M320.94,321.32A26.06,26.06,0,1,1,347,295.26,26.08,26.08,0,0,1,320.94,321.32Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,320.94,277.6Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M262,279.8A26.06,26.06,0,1,1,288,253.75,26.08,26.08,0,0,1,262,279.8Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,262,236.09Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M379.91,279.8A26.06,26.06,0,1,1,406,253.75,26.08,26.08,0,0,1,379.91,279.8Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,379.91,236.09Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M438.87,246.68a26.06,26.06,0,1,1,26.05-26.06A26.08,26.08,0,0,1,438.87,246.68Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,438.87,203Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M320.94,246.68A26.06,26.06,0,1,1,347,220.63,26.08,26.08,0,0,1,320.94,246.68Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,320.94,203Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M144.05,279.8a26.06,26.06,0,1,1,26.06-26.06A26.08,26.08,0,0,1,144.05,279.8Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,144.05,236.09Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M203,246.68a26.06,26.06,0,1,1,26.06-26.06A26.08,26.08,0,0,1,203,246.68ZM203,203a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,203,203Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M262,213.56A26.06,26.06,0,1,1,288,187.51,26.08,26.08,0,0,1,262,213.56Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,262,169.84Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M320.94,180.44A26.06,26.06,0,1,1,347,154.38,26.08,26.08,0,0,1,320.94,180.44Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,320.94,136.72Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M379.91,147.32A26.05,26.05,0,1,1,406,121.26,26.08,26.08,0,0,1,379.91,147.32Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,379.91,103.6Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M438.87,114.2a26.06,26.06,0,1,1,26.05-26.06A26.08,26.08,0,0,1,438.87,114.2Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,438.87,70.48Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M379.91,213.56A26.06,26.06,0,1,1,406,187.51,26.08,26.08,0,0,1,379.91,213.56Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,379.91,169.84Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M438.87,180.44a26.06,26.06,0,1,1,26.05-26.06A26.08,26.08,0,0,1,438.87,180.44Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,438.87,136.72Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M456.38,481.15a16.55,16.55,0,0,1-16.48-15.46l-1-14.23-1,14.26a16.51,16.51,0,0,1-33-2.17l5-67.13a14.84,14.84,0,0,1-18.12,5.63l-.12,0a14.85,14.85,0,0,1-8.6-17.56l0-.11,12.1-40.09a40.13,40.13,0,0,1,38.64-29.52h10.07a40.13,40.13,0,0,1,38.64,29.52l12.1,40.09,0,.11a14.83,14.83,0,0,1-26.85,11.8l5,67.28a16.51,16.51,0,0,1-16.47,17.63Zm-17.51-79.5a5.13,5.13,0,0,1,5.16,4.81l4.24,58.66a8.14,8.14,0,0,0,8.1,7.64,8.12,8.12,0,0,0,8.1-8.65l-5.27-70.56-2-38.91a4.2,4.2,0,0,1,8.27-1.19l8.64,36.36a6.44,6.44,0,1,0,12.49-3.12l-12.1-40.08,0-.11a31.72,31.72,0,0,0-30.56-23.38H433.84a31.72,31.72,0,0,0-30.56,23.38l0,.11-12.1,40.08a6.45,6.45,0,0,0,3.76,7.58l.12,0a6.44,6.44,0,0,0,8.68-4.43l9-36.91a4.2,4.2,0,0,1,8.26,1.26l-2.48,39.26-5.27,70.65a8.12,8.12,0,0,0,8.1,8.61,8.14,8.14,0,0,0,8.1-7.6l4.24-58.7A5.13,5.13,0,0,1,438.87,401.65Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M438.87,321.32a26.06,26.06,0,1,1,26.05-26.06A26.08,26.08,0,0,1,438.87,321.32Zm0-43.72a17.66,17.66,0,1,0,17.66,17.66A17.68,17.68,0,0,0,438.87,277.6Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
      <path
        d="M41.63,264.61a4.2,4.2,0,0,1-2.14-7.81L469.32,1.94a4.2,4.2,0,1,1,4.28,7.22L43.77,264A4.18,4.18,0,0,1,41.63,264.61Z"
        fill="#FFBC0A"
        data-original="#FFBC0A"
      ></path>
    </g>
  </svg> 
  )
}

export default ActiveUsersSvg

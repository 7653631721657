import React from 'react'

const DepartmentsSvg = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 487.8 487.8"
    style={{ enableBackground: "new 0 0 512 512" }}
    xmlSpace="preserve"
    className="icon-lg me-3"
  >
    <g>
      <g>
        <g>
          <path
            d="M310.9,334.9l-39.5-22.8c0.9-2.7,1.3-5.5,1.3-8.5c0-13.4-9.5-24.7-22.1-27.4v-45.6c0-3.3-2.7-6-6-6s-6,2.7-6,6v45.6 c-12.6,2.8-22.1,14-22.1,27.4c0,3,0.5,5.8,1.3,8.5l-39.4,22.8c-2.9,1.7-3.9,5.3-2.2,8.2c1.1,1.9,3.1,3,5.2,3c1,0,2-0.3,3-0.8 l39.5-22.8c5.2,5.6,12.6,9.2,20.8,9.2c8.2,0,15.6-3.5,20.7-9.2l39.5,22.8c0.9,0.5,2,0.8,3,0.8c2.1,0,4.1-1.1,5.2-3 C314.8,340.2,313.8,336.6,310.9,334.9z M244.7,319.7c-8.9,0-16.1-7.2-16.1-16.1c0-8.9,7.2-16.1,16.1-16.1 c8.9,0,16.1,7.2,16.1,16.1C260.8,312.5,253.6,319.7,244.7,319.7z"
            fill="#f99f1e"
            data-original="#f99f1e"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M329.3,181.9c-20.3-16.7-42.1-28.4-50.2-32.5v-29.2c4.6-4.3,7.3-10.3,7.3-16.6V67.5c0-21.3-17.3-38.6-38.6-38.6H240 c-21.3,0-38.6,17.3-38.6,38.6v36.1c0,6.3,2.7,12.3,7.3,16.5v29.2c-8.1,4.1-29.9,15.9-50.2,32.5c-5.4,4.5-8.6,11.1-8.6,18.2v24.8 c0,3.3,2.7,6,6,6s6-2.7,6-6V200c0-3.5,1.5-6.7,4.2-8.9c21.9-18,45.7-30,50.3-32.3c2.6-1.3,4.3-3.9,4.3-6.8v-34.9c0-2-1-3.9-2.7-5 c-2.9-1.9-4.6-5.1-4.6-8.6v-36c0-14.6,11.9-26.6,26.6-26.6h7.8c14.6,0,26.6,11.9,26.6,26.6v36.1c0,3.4-1.7,6.6-4.6,8.6 c-1.7,1.1-2.7,3-2.7,5v34.9c0,2.9,1.6,5.5,4.3,6.8c4.5,2.2,28.3,14.2,50.3,32.3c2.7,2.2,4.2,5.4,4.2,8.9v24.8c0,3.3,2.7,6,6,6 s6-2.7,6-6v-24.8C337.9,193,334.8,186.4,329.3,181.9z"
            fill="#f99f1e"
            data-original="#f99f1e"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M479.2,409.9c-20.3-16.7-42.1-28.4-50.2-32.5v-29.2c4.6-4.2,7.3-10.1,7.3-16.5v-36.1c0-21.3-17.3-38.6-38.6-38.6h-7.8 c-21.3,0-38.6,17.3-38.6,38.6v36.1c0,6.3,2.7,12.3,7.3,16.5v29.2c-8.1,4.1-29.9,15.9-50.2,32.6c-5.4,4.5-8.6,11.1-8.6,18.2v24.7 c0,3.3,2.7,6,6,6s6-2.7,6-6v-24.8c0-3.5,1.5-6.7,4.2-8.9c21.9-18,45.7-30,50.3-32.3c2.6-1.3,4.3-3.9,4.3-6.8v-34.9 c0-2-1-3.9-2.7-5c-2.9-1.9-4.6-5.1-4.6-8.6v-36.1c0-14.6,11.9-26.6,26.6-26.6h7.8c14.6,0,26.6,11.9,26.6,26.6v36.1 c0,3.4-1.7,6.6-4.6,8.6c-1.7,1.1-2.7,3-2.7,5v34.9c0,2.9,1.6,5.5,4.3,6.8c4.5,2.2,28.3,14.2,50.3,32.3c2.7,2.2,4.2,5.4,4.2,8.9 v24.8c0,3.3,2.7,6,6,6s6-2.7,6-6v-24.8C487.8,421,484.7,414.4,479.2,409.9z"
            fill="#f99f1e"
            data-original="#f99f1e"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M179.4,409.9c-20.3-16.7-42.1-28.4-50.2-32.5v-29.2c4.6-4.2,7.3-10.1,7.3-16.5v-36.1c0-21.3-17.3-38.6-38.6-38.6h-7.8c-21.3,0-38.6,17.3-38.6,38.6v36.1c0,6.3,2.7,12.3,7.3,16.5v29.2c-8.1,4.1-29.9,15.9-50.2,32.5C3.2,414.4,0,421,0,428.1v24.8 c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6v-24.8c0-3.5,1.5-6.7,4.2-8.9c21.9-18,45.7-30,50.3-32.3c2.6-1.3,4.3-3.9,4.3-6.8v-34.9 c0-2-1-3.9-2.7-5c-2.9-1.9-4.6-5.1-4.6-8.6v-36.1c0-14.6,11.9-26.6,26.6-26.6h7.8c14.6,0,26.6,11.9,26.6,26.6v36.1 c0,3.4-1.7,6.6-4.6,8.6c-1.7,1.1-2.7,3-2.7,5v34.9c0,2.9,1.6,5.5,4.3,6.8c4.5,2.2,28.3,14.2,50.3,32.3c2.7,2.2,4.2,5.4,4.2,8.9 v24.8c0,3.3,2.6,6,6,6c3.3,0,6-2.7,6-6v-24.8C188,421,184.9,414.4,179.4,409.9z"
            fill="#f99f1e"
            data-original="#f99f1e"
          ></path>
        </g>
      </g>
    </g>
  </svg>
  )
}

export default DepartmentsSvg

import React from "react";

const ServicesSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 420.96 420.96"
      style={{ enableBackground: "new 0 0 512 512" }}
      xmlSpace="preserve"
      className="icon-lg me-3"
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M354.48,144c0-79.529-64.471-144-144-144s-144,64.471-144,144c-20.56,2.56-40,16-40,47.2v75.2c0,35.2,24.64,48,48,48h23.2    c4.418,0,8-3.582,8-8V151.52c0-4.418-3.582-8-8-8h-15.2c0-70.692,57.308-128,128-128s128,57.308,128,128h-15.2    c-4.418,0-8,3.582-8,8v154.64c0,4.418,3.582,8,8,8h15.2c-2.24,54.96-28.64,67.84-81.36,70.16v-5.36c0-8.837-7.163-16-16-16H190.8    c-8.837,0-16,7.163-16,16v26c0,8.837,7.163,16,16,16h50.48c8.837,0,16-7.163,16-16v-4.64c50.72-2.32,94.56-12.8,97.2-86.64    c20.56-2.56,40-16,40-47.2v-75.2C394.48,159.92,375.12,146.56,354.48,144z M89.68,158.96v139.2h-15.6c-11.84,0-32-4.16-32-32    v-75.2c0-27.6,19.92-32,32-32H89.68z M241.28,392.88v12.16H190.8v-26h50.48V392.88z M378.48,266.4c0,27.6-19.92,32-32,32h-15.2    V159.52h15.6c11.84,0,32,4.16,32,32L378.48,266.4z"
              fill="#f99f1e"
              data-original="#f99f1e"
            ></path>
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
      </g>
    </svg>
  );
};

export default ServicesSvg;

import React, { useEffect, useMemo, useState } from "react";
import { decrypt } from "../../utils/encryptDecrypt/encryptDecrypt";
import useAxios from "../../utils/hook/useAxios";

const DepartmentUserInfo = () => {
    const axiosInstance = useAxios()

    const userEncryptData = localStorage.getItem("userData");
    const userDecryptData = useMemo(() => {
        return userEncryptData ? decrypt({ data: userEncryptData }) : {};
    }, [userEncryptData]);
    const userData = userDecryptData?.data;
    const departmentId = userData?.departmentId;
    const userRole = userData?.role?.roleName;

    const [dataById, setDataById] = useState([]);

    useEffect(() => {
        if (userData?.isCoreTeam === "0") {
            fetchDepartmentById();
        }
    }, []);

    const fetchDepartmentById = async () => {
        try {
            const response = await axiosInstance.post(
                `serviceManagement/department/departmentById`,
                { departmentId: departmentId }
            );
            if (response?.data) {
                const { rows, count } = response?.data?.data;
                setDataById(rows);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const getCurrentFormattedDate = () => {
        const options = { month: "short", day: "numeric", year: "numeric" };
        const today = new Date();
        const dateString = today.toLocaleDateString("en-US", options);
        return dateString.replace(",", "");
    };
    return (
        userData?.isCoreTeam === "0" && (
            <div className="col-12 mb-3 mb-lg-4 ">
                <div className="d-flex align-items-sm-center flex-sm-row flex-column mx-4">
                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center">
                            <p className="avatar-sm mb-0 fs-20 text-white d-flex align-items-center justify-content-center">
                                <img src={dataById.length > 0 ? dataById[0]?.imageData?.documentPath : ""} alt="deparment logo" className="avatar-sm rounded-circle" />
                            </p>

                            <div className="ms-3">
                                <h5 className="mb-0"> {dataById[0]?.departmentName} </h5>
                                <p className="fs-15 mt-1 text-muted mb-0"> {userRole === "Department Admin" ? "Dept. Head" : "Agent"} - {userData?.name} </p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <span className="mb-0 fs-15 text-muted current-date">
                            {getCurrentFormattedDate()}
                        </span>
                    </div>
                </div>
            </div>
        )
    );
};

export default DepartmentUserInfo;

import React, { useEffect, useRef } from "react";
import NoImage from "../../../../assets/images/NoImage copy.jpg";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import SimpleBar from "simplebar-react";
 
const TicketChatHistory = ({
    openModel,
    handleCloseModel,
    ticketChat,
    formatDateString,
}) => {
    const chatEndRef = useRef(null);
 
    useEffect(() => {
        if (openModel) {
            const timeoutId = setTimeout(() => {
                chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 100);
   
            return () => clearTimeout(timeoutId);
        }
    }, [ticketChat, openModel]);
    // <div ref={chatEndRef} />
 
    return (
        <Modal
            isOpen={openModel}
            toggle={handleCloseModel}
            id="staticBackdrop"
            size="lg"
            centered
        >
            <ModalHeader className="p-3 chat-modal">
                <div>
                    <span className="icon">
                        <i className="ri-history-line d-inline-block me-2"></i>
                    </span>
                    Chat History
                </div>
                <button
                    type="button"
                    className="btn-close"
                    onClick={handleCloseModel}
                    aria-label="Close"
                ></button>
            </ModalHeader>
            <SimpleBar style={{ maxHeight: "calc(100vh - 150px)", overflowY: "auto" }}>
                <ModalBody className="border border-dashed border-start-0 border-end-0">
                    <div className="px-3 mx-n3">
                        {ticketChat.map((logs, index) => (
                            <div className="d-flex mb-4" key={index}>
                                <div className="flex-shrink-0">
                                    <img
                                        src={logs?.documentPath || NoImage}
                                        alt=""
                                        className="rounded-circle avatar-xs me-2"
                                    />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <h5 className="fs-13">
                                        {logs?.customerName}{" "}
                                        <small className="text-muted">
                                            {formatDateString(logs?.createdDate)}
                                        </small>
                                    </h5>
                                    <p className="text-muted">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: logs?.message,
                                            }}
                                        />
                                    </p>
                                    {logs?.replys && logs.replys.map((reply, replyIndex) => (
                                        <div className="d-flex mt-3 p-3 rounded-3 bg-info-subtle" key={replyIndex}>
                                            <div className="flex-shrink-0">
                                                <img
                                                    src={reply?.documentPath || NoImage}
                                                    alt=""
                                                    className="rounded-circle avatar-xs me-2"
                                                />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="fs-13">
                                                    {reply?.customerName}{" "}
                                                    <small className="text-muted">
                                                        {formatDateString(reply?.createdDate)}
                                                    </small>
                                                </h5>
                                                <div className="text-muted ticket-chat-box mb-0">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: reply?.message,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        {/* Reference for scrolling to bottom */}
                        <div ref={chatEndRef} />
                    </div>
                </ModalBody>
            </SimpleBar>
        </Modal>
    );
};
 
export default TicketChatHistory;
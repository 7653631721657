import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
} from "reactstrap";

const CopyServiceModal = ({
    show,
    toggleShow,
    data,
    copyService,
    copyServiceLoading,
}) => {
    return (
        <Modal isOpen={show} toggle={toggleShow} centered>
            <ModalHeader toggle={toggleShow}>Duplicate Service</ModalHeader>
            <ModalBody>
                <div className=" text-center p-4">
                    <div>
                        <h5 className="">
                            A Duplicate service of{" "}
                            <strong>{data?.serviceName}</strong> will be
                            created.
                        </h5>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="flex justify-content-end gap-3">
                <Button
                    disabled={copyServiceLoading}
                    color="primary"
                    type="button"
                    onClick={copyService}>
                    {copyServiceLoading && <Spinner />}
                    Duplicate
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CopyServiceModal;

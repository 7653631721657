import React from "react";

const ClockIconSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="512"
            height="512"
            x="0"
            y="0"
            viewBox="0 0 72 72"
            style={{ enableBackground: "new 0 0 512 512" }}
            className="icon-sm me-2"
        >
            <g>
                <g>
                    <path
                        d="m69 36c0-18.2-14.8-33-33-33s-33 14.9-33 33c0 18.2 14.8 33 33 33s33-14.9 33-33zm-16.6 26.3-1.7-2.9c-.3-.5-.9-.6-1.4-.4-.5.3-.6.9-.4 1.4l1.7 3c-4.1 2.2-8.7 3.5-13.6 3.7v-3.4c0-.6-.4-1-1-1s-1 .4-1 1v3.3c-4.9-.2-9.5-1.5-13.6-3.6l1.7-3c.3-.5.1-1.1-.4-1.4s-1.1-.1-1.4.4l-1.7 2.9c-4-2.5-7.5-5.9-10-10l2.9-1.7c.5-.3.6-.9.4-1.4-.3-.5-.9-.6-1.4-.4l-3 1.7c-2-4-3.3-8.6-3.5-13.5h3.4c.6 0 1-.4 1-1s-.4-1-1-1h-3.4c.2-4.9 1.5-9.5 3.6-13.6l3 1.7c.5.3 1.1.1 1.4-.4s.1-1.1-.4-1.4l-2.9-1.7c2.5-4 5.9-7.5 10-10l1.7 2.9c.3.5.9.6 1.4.4.5-.3.6-.9.4-1.4l-1.7-3c4-2 8.6-3.3 13.5-3.5v3.4c0 .6.4 1 1 1s1-.4 1-1v-3.4c4.9.2 9.5 1.5 13.6 3.6l-1.7 3c-.3.5-.1 1.1.4 1.4s1.1.1 1.4-.4l1.7-2.9c4 2.5 7.5 5.9 10 10l-2.9 1.7c-.5.3-.6.9-.4 1.4.3.5.9.6 1.4.4l3-1.7c2.2 4.1 3.5 8.7 3.7 13.6h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.2c-.2 4.9-1.5 9.5-3.6 13.6l-3-1.7c-.5-.3-1.1-.1-1.4.4s-.1 1.1.4 1.4l2.9 1.7c-2.5 3.9-5.9 7.3-9.9 9.8z"
                        fill="#495057"
                        data-original="#495057"
                    ></path>
                    <path
                        d="m53.4 35h-14c-.3-1.2-1.3-2.1-2.4-2.4v-19.2c0-.6-.4-1-1-1s-1 .4-1 1v19.2c-1.5.4-2.6 1.8-2.6 3.4 0 2 1.6 3.6 3.6 3.6 1.6 0 3-1.1 3.4-2.6h14c.6 0 1-.4 1-1s-.5-1-1-1zm-17.4 2.6c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6c0 .9-.7 1.6-1.6 1.6z"
                        fill="#495057"
                        data-original="#495057"
                    ></path>
                </g>
            </g>
        </svg>
    );
};

export default ClockIconSvg;
